import { Modal, Button } from 'react-bootstrap';

export default function ConfirmationModal(props) {
    const handleClose = () => props.setModal((prevState) => ({ ...prevState, show: false }));

    return (
        <Modal show={props.modal.show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>You will be redirected to an external site to access our product page.</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>

                <Button variant="primary" href={props.modal.url}>
                    Proceed
                </Button>
            </Modal.Footer>
        </Modal>
    );

}
