
import { Container } from 'react-bootstrap';
import WorkshopForm from '../components/WorkshopForm';

export default function Workshop() {
    return (
        <Container className='text-start'>
            <h5 className='header my-4'>{'Workshops'}</h5>
            <p className='text-center'><img style={{width:'98%'}} src={'https://frescopad.com/wp-content/uploads/2020/10/online-workshop-png.png'} alt={``} /></p>
            <WorkshopForm />
        </Container >
    );
}