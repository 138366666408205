import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Form, Button, Spinner } from 'react-bootstrap';
import { IoCheckmarkCircleSharp, IoCloseCircle } from "react-icons/io5";

export default function WorkshopForm() {
    const [status, setStatus] = useState('');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setStatus('loading')
        emailjs.sendForm('service_t9y4tn6', 'template_v4grbft', form.current, 'user_nLC8qFvMnZEMeJjvzUFfD')
            .then((result) => {
                setStatus('complete')
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
                setStatus('error')
            });

    };
    function renderByStatus(status) {
        switch (status) {
            case 'loading':
                return <Spinner className='align-middle' animation="border" />;

            case 'complete':
                return <div className='text-center' >
                    <div><IoCheckmarkCircleSharp style={{ color: 'green', fontSize: '3rem' }} /></div>
                    <div >Email sent, we will contact you shortly.</div>
                </div>

            case 'error':
                return <div className='text-center' >
                    <IoCloseCircle style={{ color: 'red', fontSize: '3rem' }} /><br></br>Error has occured, please try again later.
                </div>;
            default:
                return <Form className='w-100' ref={form} onSubmit={sendEmail}>
                    <h5 className="mb-2">Enquire about our workshops</h5>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Form.Label >Workshop of Interest</Form.Label>
                        <Form.Select name="workshop" >
                            <option value='Resin'>{'Resin'}</option>
                            <option value='Tote Bag'>{'Tote & Drawstring Bag Painting'}</option>
                            <option value='Keychain'>{'Oven Baked Keychain'}</option>
                        </Form.Select>

                    </Form.Group>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Form.Label >Name</Form.Label>
                        <Form.Control type="text" name="name" required />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                        <Form.Label >Email address</Form.Label>
                        <Form.Control type="email" name="email" required />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
                        <Form.Label >Message</Form.Label>
                        <Form.Control as="textarea" name="message" rows={3} />
                    </Form.Group>
                    <Button className='mt-3' variant="primary" type="submit">
                        Submit
                    </Button>
                </Form >;
        }
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {renderByStatus(status)}

        </div>
    );
}