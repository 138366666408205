import React, { useState } from "react";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import ConfirmationModal from "./ConfirmationModal";

const NavigationBar = () => {
    const [modal, setModal] = useState({ show: false, title: 'Products', url: 'https://www.etsy.com/sg-en/shop/craftoverflowsg' });

    return (
        <div className="py-3" style={{ borderBottom: '1px solid #aaaaaa' }} >
            <div>
                <Row className='p-0 m-0 text-center'>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <div className="logo" style={{
                                fontSize: '3rem',
                            }}>CraftOverflow</div>
                        </Link>
                </Row>
            </div>
            <div >
                <div style={{
                    flex: 1,
                    textAlign: 'center'
                }}>
                    <Link to="/" className='px-2 text-dark styled'>HOME</Link>
                    <span className='px-2 text-dark styled' style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() =>
                        setModal((prevState) => ({ ...prevState, show: true }))
                    }>SHOP</span>
                    <Link to="/workshops" className='px-2 text-dark styled'>WORKSHOPS</Link>

                </div>
            </div>
            <ConfirmationModal modal={modal} setModal={setModal} />
        </div>
    );
};

export default NavigationBar;
