import About from '../components/About';
import Banner from '../components/Banner';
import Collections from "../components/Collections";

export default function Home() {
    return (<>
        <Banner />
        <About/>
        <Collections />

    </>
    );
}