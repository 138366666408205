
import { Container } from 'react-bootstrap';

export default function PaymentShipping() {
    return (
        <Container className='text-start'>
            <h5 className='header my-4'>{'Payment & Shipping'}</h5>
            <p style={{ 'fontStyle': 'italic' }}>CraftOverflow Currently Does Not Ship Internationally</p>
            <strong  >Payment Information (LOCAL)</strong>

            <div>
                <ul >
                    <li>Internet Banking</li>
                    <li>Stripe</li>
                </ul>
                <p>Payment information will be shown upon checkout. For Internet Banking, payment must be made within an hour.</p>

                <p><strong>Shipping Information</strong></p>
                <p>CraftOverflow uses JNT Express as our local courier service.</p>

                <p>After payment has been processed, we aim to dispatch all customised orders within 3 days - 1.5 weeks and for normal orders, we will dispatch it within 3 business days</p>
                <table className='text-center' style={{ border: 'none', width: '100%' }}>
                    <tr style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                        <th>Service</th>
                        <th>Cost</th>
                        <th>Delivery Time</th>
                    </tr>
                    <tr style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                        <td>Standard Courier</td>
                        <td>S$2.80</td>
                        <td>1-3 working days</td>

                    </tr>

                </table>
            </div>



        </Container >
    );
}