import React from "react";
import { FaInstagram, FaTiktok } from 'react-icons/fa';


const Footer = () => {
  return (
    <footer className="container py-5">
      <div className="row">
        <div className="col-6 col-md">
          <div style={{
            fontSize: 'xx-large',
            fontFamily: 'Carattere',
            color: '#292929',
            fontStyle: 'italic',
            fontWeight: 100
          }}>CraftOverflow</div>
          <ul className="list-unstyled text-small">
            <li><small className="d-block mb-1 text-muted">© 2021-2022</small></li>
            <li>
              <a href="https://www.instagram.com/craftoverflow/" target="_blank" rel="noreferrer" className="pe-2">
                <FaInstagram style={{ color: 'black', fontSize: 'large' }} />
              </a>
              <a href="https://www.tiktok.com/@craftoverflow" target="_blank" rel="noreferrer" >
                <FaTiktok style={{ color: 'black', fontSize: 'large' }} />
              </a>
            </li>
          </ul>

        </div>
        <div className="col-6 col-md">
          <h5 className='header'>Customer Care</h5>
          <ul className="list-unstyled text-small">
            <li><a className="text-muted" style={{ textDecoration: 'none' }} href="/contact">{'Contact Us'}</a></li>
            <li><a className="text-muted" style={{ textDecoration: 'none' }} href="/payment-shipping">{'Payment & Shipping'}</a></li>
            <li><a className="text-muted" style={{ textDecoration: 'none' }} href="/return-exchange-policy">{'Return & Exchange'}</a></li>

          </ul>
        </div>

        {/* <div className="col-6 col-md">
          <h5 className='header'>About Us</h5>
          <ul className="list-unstyled text-small">
            <li><a className="text-muted" href="/" >Team</a></li>
            <li><a className="text-muted" href="/">Privacy</a></li>
            <li><a className="text-muted" href="/">Terms</a></li>
          </ul>
        </div> */}

      </div>


    </footer>

  );
};
export default Footer;




