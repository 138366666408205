
import divider from '../divider.svg';

const About = () => {

    return (
        <>
            <div className='pt-2 pb-3' style={{ border: '1px solid rgba(200,200,200,0.5)' }} >
                <img src={divider} alt="" style={{ height: 'auto', width: '200px' }} />
                <h5 className='header'>About Us</h5>
                {/* Display this for LARGE SCREEN */}
                <p className='text-center d-none d-lg-block text-small' >
                    Founded on 10-10-2021, CraftOverflow is a humble local business that aims to be the one-stop platform for all Do It Yourself (DIY) crafts.<br></br>

                    We are a team of enthusiasts who wish to share the joy of craft with everyone and hence our brand name – CraftOverflow,
                    which refers to the endless overflow of craft! We aspire to bring you all kinds of DIY kits in the near future, to promote creative thinking and most importantly,
                    to have a fun time! ❤️
                </p>

                {/* Display this for MOBILE */}
                <p className='text-center d-lg-none text-small'>
                    Founded on 10-10-2021, CraftOverflow is a humble local business that aims to be the one-stop platform for all DIY crafts ❤️
                </p>
            </div>

        </>
    );
};

export default About;
