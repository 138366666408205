
const Announcement = () => {
  return <div style={{
    backgroundColor: '#FCF3EC',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    Super Deal! Free Shipping on Orders Over $50 with code FREESHIPPING</div>;
};

export default Announcement;
