export const sliderItems = [
  {
    img: "https://res.cloudinary.com/dc2qflvds/image/upload/v1643634134/banner1_hwq7n0.png",
    url: "https://www.etsy.com/sg-en/shop/craftoverflowsg",
    external: true
  },
  {
    img: "https://res.cloudinary.com/dc2qflvds/image/upload/v1643634136/banner2_t8dbhg.png",
    url: "https://www.etsy.com/sg-en/shop/craftoverflowsg",
    external: true
  },
  {
    img: "https://res.cloudinary.com/dc2qflvds/image/upload/v1643634132/banner3_iocjji.png",
    url: "/workshops",
    external: false
  }
];


export const categoryItems = [
  {
    title: 'DIY Kits',
    img: "https://res.cloudinary.com/dc2qflvds/image/upload/v1643634157/4_qlnofm.png",
    url: "https://www.etsy.com/sg-en/shop/craftoverflowsg?section_id=35512073"
  },
  {
    title: 'Keychains',
    img: "https://res.cloudinary.com/dc2qflvds/image/upload/v1643634149/6_k4tw6x.png",
    url: "https://www.etsy.com/sg-en/shop/craftoverflowsg?section_id=36987054"
  },
  {
    title: 'Coasters',
    img: "https://res.cloudinary.com/dc2qflvds/image/upload/v1643634148/5_dgfpve.png",
    url: "https://www.etsy.com/sg-en/shop/craftoverflowsg?section_id=36987060"
  }
];

