
import { Container } from 'react-bootstrap';

export default function ReturnExchange() {
    return (
        <Container className='text-start'>
            <h5 className='header my-4'>{'Return & Exchange Policy'}</h5>
            <p>Our policy lasts 7 days. If 7 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange. We reserve the right to refuse any exchange if any of the below conditions are not met.</p>
            <p>We only accepts that are defective or damaged. To be eligible for a return, your item must be unused and in the same condition that you received it. </p>
            <p>Several types of goods are exempt from being returned. This includes -</p>
            <ul >
                <li>Gift Cards</li>
                <li>Customized gifts</li>
                <li>Workshop fees</li>
                <li>Workshop packages</li>
                <li>Downloadable software products</li>
            </ul>
            <p>To complete your return or exchange, we require a receipt or proof of purchase, email us at craftoverflowsg@gmail.com with the email subject "Return/Exchange – Order #XXXXX"</p>

            <p>We will follow up with you within 3 business days with regards to the next step.</p>
            <p>Thank you!</p>

        </Container >
    );
}