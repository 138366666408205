import { Carousel } from 'react-bootstrap';
import { sliderItems } from "../data";
import ConfirmationModal from './ConfirmationModal';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function Banner() {
    const [modal, setModal] = useState({ show: false, title: 'Products', url: 'https://www.etsy.com/sg-en/shop/craftoverflowsg' });
    let navigate = useNavigate();
    return (
        <>
            <Carousel variant='dark'>
                {sliderItems.map((item, idx) => (
                    <Carousel.Item key={idx} interval={3000} >
                        <img
                            className="d-block w-100"
                            style={{ cursor: 'pointer' }}
                            src={item.img}
                            alt={`slide ${idx}`}
                            onClick={() => {
                                if (item.external) {
                                    setModal((prevState) => ({ url: item.url, title: 'Products', show: true }))
                                } else {
                                    navigate(item.url);
                                }
                            }}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            <ConfirmationModal modal={modal} setModal={setModal} />
        </>
    );
}
