import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConfirmationModal from './ConfirmationModal';
import { categoryItems } from "../data";
import { useState } from 'react';
const Collections = () => {
  const [modal, setModal] = useState({ show: false, title: 'Products', url: 'https://www.etsy.com/sg-en/shop/craftoverflowsg' });

  return (
    <div className='pt-4 ' style={{ border: '1px solid rgba(200,200,200,0.5)' }} >
      <h5 className='header'>Collections</h5>
      <Row className='m-2'>
        {categoryItems.map((item, idx) => (
          <Col key={idx} xs={4} className='p-1 CategoryCard' style={{ cursor: 'pointer' }} onClick={() => setModal((prevState) => ({ url: item.url, title: item.title, show: true }))}>
            <div style={{ height: '100%', width: '100%', textAlign: 'center', alignItems: 'center', position: 'relative' }}>
              <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={item.img} alt='loading..' />

              {/* <div style={{ position: 'absolute', bottom: '35%', width: '100%', textAlign: 'center' }}>
                <div style={{ fontSize: '2rem', color: 'white', marginBottom: '1rem' }}>{item.title}</div>
                <Button variant='light' style={{ backgroundColor: 'white', color: 'black' }} >SHOP NOW</Button>
              </div> */}
            </div>
          </Col>
        ))}
      </Row>
      <ConfirmationModal modal={modal} setModal={setModal} />

    </div>
  );
};

export default Collections;
