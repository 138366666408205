import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'
import Workshop from './pages/Workshop';
import NavigationBar from './components/NavigationBar';
import Announcement from './components/Announcement';
// import logo from './logo.svg';
import './App.css';
import Footer from './components/Footer';
import PaymentShipping from './pages/PaymentShipping';
import ReturnExchange from './pages/ReturnExchange';
import ContactUs from './pages/ContactUs';
function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Announcement />
        <NavigationBar />

        {/* ROUTES START */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/workshops" element={<Workshop />} />
          <Route exact path="/payment-shipping" element={<PaymentShipping />} />
          <Route exact path="/return-exchange-policy" element={<ReturnExchange />} />
          <Route exact path="/contact" element={<ContactUs />} />

        </Routes>
        {/* ROUTES END */}
        <Footer />
      </BrowserRouter>
    </div>

  );
}

export default App;